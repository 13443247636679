@use "sass:color"; @use "sass:math"; @use "@/web/client/assets/css/variables" as *; 
.contact{
    display: flex;
    flex-direction: column;
    gap: $padding;

    &.dark{
        a{
            color: white;
        }
    }

    .link{
        align-items: center;
        display: flex;
        gap: $padding;
        line-height: $icon-size;
    }
}
