@use "sass:color"; @use "sass:math"; @use "@/web/client/assets/css/variables" as *; 
.container{
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);

    @media (max-width: $mobile-breakpoint) {
        grid-template-columns: auto;

        aside {
            grid-row-start: 2;
        }
    }

    aside{
        img{
            border-radius: 50%;
            display: block;
            margin: 0 auto;

            max-width: 100%; height: 100%;
            object-fit: cover;
        }

        .contact{
            margin: 0 auto;
            width: -moz-fit-content;
            width: fit-content;
        }
    }
}
