@use 'sass:math';
@use 'sass:color';

// ----------------------------------------------------------------------------
// App
// ----------------------------------------------------------------------------

$transition: 0.5s;
$ratio: 1.618;
$padding: 16px;
$hspace: $padding * 2;
$vspace: $padding * 8;

$icon-size: $padding * 1.5;
$container-width: math.div(1, $ratio) * 100%;
$max-container-width: 900px;
$mobile-breakpoint: 1280px - 1px;

$header-line-height: $padding * 2;
$header-height: $header-line-height + ($padding * 2);
$header-height-mobile: $header-height + (($padding * 2) * 2 + $header-line-height);

$footer-line-height: $padding * 2;
$footer-height: (($padding * 2) * 2) + $footer-line-height;

// ----------------------------------------------------------------------------
// App Colors
// ----------------------------------------------------------------------------

$primary   : #0077b8;
$dark      : #111;
$positive  : #21BA45;
$negative  : #C10015;
$info      : #31CCEC;
$warning   : #F2C037;

$primary-on-dark:   color.adjust($primary, $lightness: 20%);
$text-on-dark:      rgba(#ccc, 0.8);
$light-border:      rgba(#222, 0.1);
$light-bg:          rgba(#ccc, 0.1);
